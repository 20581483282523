import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';
import G from './G';

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    setFetchAttempted(true);

    fetch('/api/location')
      .then((response) => response.json())
      .then((data) => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));

        if (decodedCity === 'unknown') {
          fetch('https://freeipapi.com/api/json/')
            .then((response) => response.json())
            .then((externalData) => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData);
            })
            .catch((error) => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase()));
          setCity(decodedCity);
          console.log(data);
        }
      })
      .catch((error) => {
        console.error('Fetch error on local API:', error);
      });
  }, []);

  const imageUrls = [
    'https://i.ibb.co/r7gBbfF/cropped-photo-2024-02-26-13-47-52-1.jpg',
    'https://i.ibb.co/yS9N4yp/cropped-photo-2024-02-26-13-47-49-1.jpg',
    'https://i.ibb.co/DbNG5qV/cropped-photo-2024-02-26-13-47-47-1.jpg',
    'https://i.ibb.co/19HwKPf/cropped-photo-2024-02-26-13-47-45-1.jpg',
    'https://i.ibb.co/pJKXBCs/cropped-photo-2024-02-26-13-47-39-1.jpg',
    'https://i.ibb.co/px6bt82/cropped-photo-2024-07-16-22-30-45.jpg',
    'https://i.ibb.co/7XnS6zV/cropped-photo-2024-07-16-22-30-47.jpg'
  ];

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="container">
            <img 
              src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
              alt="Header Image"
              className="header-image"
            />
            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              slidesPerView={'2'}
              coverflowEffect={{
              rotate: 15,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
             }}
            autoplay={{ // Corrected from autoPlay to autoplay
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              // Screens smaller than 640px
              640: {
                slidesPerView: 2,
              },
              // Larger screens
              640: {
                slidesPerView: 3, // or whatever number you prefer
              },
            }}
            modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
            className="swiper-container"
          >
            {imageUrls.map(url => (
              <SwiperSlide key={url} className="swiper-slide">
                <img src={url} alt="slide_image"/>
              </SwiperSlide>
            ))}
              <div className="slider-controler">
                <div className="swiper-pagination"></div>
              </div>
            </Swiper>
        
            <div className='textContainer'>
              <img src='https://i.ibb.co/Y85ntW3/cropped-photo-2024-02-26-13-47-49-1-modified.png'/>
              <section>
                  <div className='flexRow'>Danura❤️ <div className='buttonStyling'>Online Now</div></div>
                  <div className='flexRow'>
                    <span>
                    Your fav petite and innocent 18yo currently in {city}, {region}   {country && <img 
                        src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                        alt={`${country} Flag`}
                        style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
                      />} 
                  
                      
                    </span>
                  </div>
        
        
              </section>
            </div>
        
            <a href="https://onlyfans.com/sweetdanura/c27" id="customButton">
              Send me a message
            </a>
        
            <p className="exclusiveContent">
            <span>
            Exclusive Content🔥 80% OFF For 10 PEOPLE ONLY <br/><br/>
            Looking for content partners while im travelling, message me on Onlyfans if your close and want to shoot spicy content 👀
                      </span>
            </p>
            <Analytics/>
          </div>
          }
        />
        <Route path="/Come-Talk-To-Me/onlyfans.com/sweetdanura" element={<G />} />
      </Routes>
    </Router>
  );
}

export default App;
